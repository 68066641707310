<template>
<v-container fluid>
    <v-row dense>
        <v-col cols="12" md="12">
            <v-card :elevation="1" color="#26c6da2b">
                <v-card-text class="py-1">
                    <v-row dense>
                        <v-col cols="12" md="2">
                            <v-combobox
                                label="Department"
                                dense
                                outlined
                                hide-details
                                :items="$store.getters['department/departments']"
                                v-model="department"
                                item-text="name"
                                item-value="id"
                                :loading="$store.getters['department/loadingDepartments']"
                                @focus="$store.dispatch('department/getDepartments')"
                                class="custom-form"
                            ></v-combobox>
                        </v-col>
                        
                        <v-col cols="12" md="2">
                            <v-combobox
                                label="Designation"
                                dense
                                outlined
                                hide-details
                                :items="$store.getters['designation/designations']"
                                v-model="designation"
                                item-text="name"
                                item-value="id"
                                :loading="$store.getters['designation/loadingDesignations']"
                                @focus="$store.dispatch('designation/getDesignations')"
                                class="custom-form"
                            ></v-combobox>
                        </v-col>
                        
                        <v-col cols="12" md="2">
                            <v-combobox
                                label="Shift"
                                dense
                                outlined
                                hide-details
                                :items="$store.getters['shift/shifts']"
                                v-model="shift"
                                item-text="name"
                                item-value="id"
                                :loading="$store.getters['shift/loadingShifts']"
                                @focus="$store.dispatch('shift/getShifts')"
                                class="custom-form"
                            ></v-combobox>
                        </v-col>

                        <v-col cols="12" md="2">
                            <v-combobox
                                label="Employee"
                                dense
                                outlined
                                hide-details
                                v-model="employee"
                                :items="$store.getters['employee/employees']"
                                :loading="$store.getters['employee/loadingEmployees']"
                                item-text="display_name"
                                item-value="id"
                                class="mb-1 custom-form"
                                @focus="getEmployees"
                            ></v-combobox>
                        </v-col>
                       
                        <v-col cols="6" md="1">
                            <v-menu
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model="date_from"
                                        label="Date From"
                                        v-on="on"
                                        class="custom-form"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date_from"></v-date-picker>
                            </v-menu>
                        </v-col>
                        
                        <v-col cols="6" md="1">
                            <v-menu
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        dense
                                        outlined
                                        hide-details
                                        v-model="date_to"
                                        label="Date To"
                                        v-on="on"
                                        class="custom-form"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="date_to"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="6" md="1">
                            <v-btn
                                elevation="1"
                                small
                                color="primary"
                                dark
                                style="margin-top:-2px"
                                :loading="loading"
                                @click="getReports"
                            > Search </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="records.length > 0">
        <v-col cols="12">
            <v-btn
                color="primary"
                class="white--text"
                small
                dense
                @click="print"
            >
                <v-icon left small dense> mdi-printer </v-icon>
                Print
            </v-btn>
        </v-col>
        <v-col cols="12" id="reportContent">
            <v-simple-table dense class="record_table">
                    <tr class="record_heading_tr">
                        <th>SL.</th>
                        <th>Date</th>
                        <th>E-ID</th>
                        <th>Bio-ID</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Mobile</th>
                        <th>Shift</th>
                        <th>OT Hours</th>
                        <th>Remark</th>
                        <th>Action</th>
                    </tr>

                    <tr v-for="(record, sl) in records" :key="sl">
                        <td>{{sl + 1}}</td>
                        <td>{{record.date}}</td>
                        <td>{{record.emp_id}}</td>
                        <td>{{record.biometric_id}}</td>
                        <td style="text-align: left;">{{record.employee_name}}</td>
                        <td style="text-align: left;">{{record.designation_name}}</td>
                        <td style="text-align: left;">{{record.employee_phone}}</td>
                        <td style="text-align: left;">{{record.shift_name}}</td>
                        <td>{{record.ot_hours}}</td>
                        <td>{{record.remark}}</td>
                        <td>
                            <v-tooltip bottom v-if="$store.state['user'].role != 'User'">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="editOvertime(record, sl)">
                                        <v-icon small color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="$store.state['user'].role == 'Admin'">
                                <template v-slot:activator="{ on }">
                                    <v-icon small color="error" v-on="on" @click="deleteOvertime(record.id, sl)">mdi-delete-circle-outline</v-icon>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="8" class="text-right">Total=</th>
                        <th>
                            {{ 
                                records.reduce((prev, curr)=>{
                                    return prev + +curr.ot_hours;
                                }, 0)
                            }}
                        </th>
                        <th></th>
                        <th></th>
                    </tr>
            </v-simple-table>
        </v-col>
    </v-row>
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
        >
        <v-card>
            <v-card-title>
            <span class="text-h5">Overtime Update</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-row>
                <v-col cols="12">
                    <v-card
                        class="mx-auto"
                        outlined
                    >
                        <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5 mb-1">
                            {{edit_data.employee_name}} ({{edit_data.emp_id}})
                            </v-list-item-title>
                            <v-list-item-subtitle>{{edit_data.designation_name}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{edit_data.employee_phone}}</v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-x
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                dense
                                outlined
                                hide-details
                                v-model="edit_data.date"
                                label="Date"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="edit_data.date"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-text-field
                        type="text"
                        dense
                        outlined
                        hide-details
                        label="OT"
                        v-model="edit_data.ot_hours"
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                    <v-text-field
                        type="text"
                        dense
                        outlined
                        hide-details
                        label="Remark"
                        v-model="edit_data.remark"
                    ></v-text-field>
                </v-col>
                </v-row>
            </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
                Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="updateOvertime"
            >
                Update
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</v-container>
</template>
<script>
    import utility from '../../mixins/utility.mixin';
    export default {
        mixins: [utility],
        data () {
            return {
                date_from  : this.toISOLocal().substr(0, 10),
                date_to    : this.toISOLocal().substr(0, 10),
                department : null,
                designation: null,
                shift      : null,
                employee   : null,
                records    : [],
                loading    : false,
                dialog     : false,
                edit_index : null,
                edit_data  : {},
            }
        },
        watch: {
          dialog(d){
            if(!d){
                this.edit_data  = {};
                this.edit_index = null;
            }
          }  
        },
        methods: {
            getEmployees() {
                this.employee = null;
                this.$store.dispatch('employee/getEmployees', {
                    apiParams: {
                        departmentId    : this.department?.id ?? null,
                        designationId   : this.designation?.id ?? null,
                        shiftId         : this.shift?.id ?? null,
                    },
                })
            },
            async getReports(){
                this.loading = true;

                this.records = await this.$store
                .dispatch('ot/getOvertimeList', {
                    departmentId    : this.department?.id ?? null,
                    designationId   : this.designation?.id ?? null,
                    shiftId         : this.shift?.id ?? null,
                    employeeId      : this.employee?.id ?? null,
                    date_from       : this.date_from,
                    date_to         : this.date_to,
                });

                this.loading = false;
            },
            async deleteOvertime(id, ind) {
                let deleteConf = confirm('Are you sure?');
                if(deleteConf == false){
                    return;
                }
                let isSuccess = await this.$store.dispatch('ot/deleteOvertime', id);
                if(isSuccess) {
                    this.records.splice(ind, 1);
                }
            },
            editOvertime(data, ind){
                this.edit_data  = data;
                this.edit_index = ind;
                this.dialog     = true;
            },
            async updateOvertime(){
                let isSuccess = await this.$store.dispatch('ot/updateOvertime', this.edit_data);

                if(isSuccess){
                    this.records[this.edit_index] = this.edit_data;
                    this.dialog = false;
                }
            },
            async print(){
                let date_text = '('+this.date_from+' to '+this.date_to+')';
                let title = 'Overtime Record ';

                title += date_text;

                let company_profile = this.$store.getters['company/company'];

                // Get HTML to print from element
                const prtHtml = document.getElementById('reportContent').innerHTML;

                // Get all stylesheets HTML
                let stylesHtml = '';
                for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                    stylesHtml += node.outerHTML;
                }

                // Open the print window
                // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
                const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                </head>
                <body>
                    <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                        <div class="container container--fluid">
                            <div class="row row--dense">
                                <div class="col-2 px-2">
                                    <img style="width: 100%; height: 50px;" src="${this.$store.state.host+company_profile.logo}">
                                </div>
                                <div class="col-8 px-2 text-center">
                                    <span class="font-weight-black">
                                        ${company_profile.name}
                                    </span>
                                    <br>
                                    <span>${company_profile.address}</span>
                                    <br>
                                    <span>
                                        Email:${company_profile.email}, Contact:${company_profile.phone}
                                    </span>
                                </div>
                                <div class="col-2 px-2">
                                    <img style="width: 100%; height: 50px;" src="${this.$store.state.host+'linkup.png'}">
                                </div>
                                <div class="col-12 px-2 text-center">
                                    <p class="font-weight-black custom_header">
                                        ${title}
                                    </p>
                                </div>
                                <div class="col col-12 px-2">
                                    ${prtHtml}
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
                </html>`);

                WinPrint.document.head.innerHTML += `
                    <style>
                        .v-data-table__wrapper{
                            overflow: unset;
                        }
                        .record_heading_tr th{
                            text-align: center !important;
                        }
                        .custom_header{
                            background-color: #0000001f;
                            border-radius: 4px;
                            margin-bottom: 5px !important;
                        }
                        tr:hover{
                            background: unset !important;
                        }
                        .record_heading_tr:hover{
                            background: #4caf7773 !important;
                        }
                        @media print {
                            table.record_table { page-break-after:auto }
                            table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                            table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                            table.record_table thead { display:table-header-group }
                            table.record_table tfoot { display:table-footer-group }
                        }
                    </style>
                `;

                let rows = WinPrint.document.querySelectorAll('.record_table tr');
                rows.forEach(row => {
                    row.lastChild.remove();
                })

                WinPrint.focus();
                WinPrint.document.close();
                await new Promise(resolve => setTimeout(resolve, 2000));
                WinPrint.print();
                WinPrint.close();
            }

        },

    }
</script>

<style scoped>
    .record_table{
        margin-bottom: 5px;
    }
    .record_table table, .record_table th, .record_table td {
        border: 1px solid #dee2e6;
        border-collapse: collapse;
        padding: 0 !important;
        
    }
    .record_table th, .record_table td {
        padding: 2px !important;
        font-size: 11px !important;
        text-align: center;
        height: 0 !important;
    }
    .record_heading_tr{
        background: #4caf7773;
    }
</style>